import { GlobalMessagePurpose, LocalMessagePurpose } from '@norges-domstoler/dds-components';

interface MyInterface {
    types: Record<number, LocalMessagePurpose>;
}

interface NewInterface {
    types: Record<number, GlobalMessagePurpose>;
}

export const messageTypes: MyInterface = {
    types: {
        10: 'info',
        20: 'warning',
    },
};

export const globalMessageTypes: NewInterface = {
    types: {
        10: 'info',
        20: 'warning',
    },
};
