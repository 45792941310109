export enum LanguageTag {
    NORWEGIAN = 'no',
    SAMI = 'se',
    ENGLISH = 'en',
}

export const languageFullNames: Record<LanguageTag, string> = {
    [LanguageTag.NORWEGIAN]: 'Norsk',
    [LanguageTag.SAMI]: 'Sámegiella',
    [LanguageTag.ENGLISH]: 'English',
};

export type LanguageType = LanguageTag;

export const getValidLanguage = (language: string): LanguageTag => {
    return (Object.values(LanguageTag) as string[]).includes(language)
        ? (language as LanguageTag)
        : LanguageTag.NORWEGIAN;
};

export const getLanguageFullName = (language: LanguageTag): string => {
    return languageFullNames[language];
};

export const languageNames: { [id: string]: string } = {
    no: languageFullNames[LanguageTag.NORWEGIAN],
    se: languageFullNames[LanguageTag.SAMI],
    en: languageFullNames[LanguageTag.ENGLISH],
};
